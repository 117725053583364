const btnHeader = document.getElementById("btnHeader");
const btnHeaderMobile = document.getElementById("btnHeaderMobile");
const main = document.getElementById("main");
const btnCloseHeaderMobile = document.getElementById("btnCloseHeaderMobile");
const headerMobileButton = document.getElementById("headerMobileButton");
const navMobile = document.getElementById("navMobile");
const enquiryMobileButton = document.getElementById("enquiryMobileButton");
const enquiryButton = document.getElementById("enquiryButton");
const contact = document.getElementById("contact");
const ctaBar = document.getElementById("cta-bar");
const btnClosePopup = document.getElementById("btnClose");
const bell = document.getElementById("bell");
const popUp = document.getElementById("popUp");
const popUpHeadLine = document.getElementById("popupHeadLine");
const btnDropDown = document.querySelector(".btnDropDown");
const dropDown = document.querySelector(".dropDown");
const btnDropDownMobile = document.querySelector(".btnDropDownMobile");
const dropDownMobile = document.querySelector(".dropDownMobile");

//observer API header
const header = document.querySelector("header");
const sectionOne = document.querySelector(".hero");
const mediaQueryMin1024 = window.matchMedia("(min-width:1024px)");
const mediaQuery500 = window.matchMedia("(max-width:500px)");
let scrollRemove = false;

const sectionOneOptions = {
  rootMargin: "-100px 0px 0px 0px",
  threshhold: 1,
};

const sectionOneObserver = new IntersectionObserver(function (
  entries,
  sectionOneObserver
) {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {
      header.classList.add("lowWidth");
      ctaBar.style.setProperty("--spacingBottom", "-70px");
      scrollRemove = true;

      if (mediaQuery500.matches) {
        bell.style.bottom = "20px";
      } else if (mediaQueryMin1024.matches) {
        popUpHeadLine.style.opacity = "1";
        popUp.style.pointerEvents = "unset";
      }
    } else {
      header.classList.remove("lowWidth");
      ctaBar.style.setProperty("--spacingBottom", "0px");
      scrollRemove = false;

      if (mediaQuery500.matches) {
        bell.style.bottom = "80px";
      } else if (mediaQueryMin1024.matches) {
        popUpHeadLine.style.opacity = "0";
        popUp.style.pointerEvents = "none";
      }
    }
  });
},
sectionOneOptions);
sectionOneObserver.observe(sectionOne);

// scroll Remove Class Header
$("main").scroll(() => {
  let scroll = $("main").scrollTop();
  if (scrollRemove == true) {
    if (scroll >= 150) {
      $("header").addClass("lowWidth");
    }
  }
});

// Open header
$("#btnHeader").click(() => $("header").removeClass("lowWidth"));

// Open header mobile
$("#btnHeaderMobile").click(() => {
  $("#main").addClass("openHeaderMobile");
  $("header").css("transform", "translateY(-100px)");
  $("#headerMobileButton").css("transform", "translateY(0)");
  $("#headerMobileButton").css("transitionDelay", "1s");
  $("#cta-bar").css("display", "none");
  $("#bell").css("display", "none");
});

// Close header mobile
function closeHeader() {
  $("#main").removeClass("openHeaderMobile");
  $("header").css("transform", "translateY(0)");
  $("#headerMobileButton").css("transform", "translateY(100%)");
  $("#headerMobileButton").css("transitionDelay", "0");
  $("#cta-bar").css("display", "block");
  $("#bell").css("display", "flex");
}
$("#btnCloseHeaderMobile").click(() => {
  closeHeader();
});

// Scroll to Contact
function scrollInToContact() {
  $("main").animate(
    {
      scrollTop: $("#contact-section").offset().top,
    },
    500
  );
}
$("#enquiryMobileButton").click(() => {
  closeHeader();
  scrollInToContact();
});

$("#enquiryButton").click(() => {
  scrollInToContact();
});

// Open Video
$("#videoButton").click(() => $("#videoHero").css("display", "flex"));

// Close Video
$("#CloseVideo").click(() => $("#videoHero").css("display", "none"));

// Open Popup
$("#bell, #popupHeadLine").click(() => $("#popUp").addClass("openPopup"));

// Close Popup
$("#btnClose").click(() => $("#popUp").removeClass("openPopup"));

// Open Drop Down
$(".btnDropDown, .btnDropDownMobile").click(() => {
  $(".dropDown").toggleClass("openDropDown");
  $(".dropDownMobile").toggleClass("openDropDownMobile");
});

// AOS
AOS.init({
  duration: 500,
});

// Flag tel
window.intlTelInput(document.querySelector('[type="tel"]'), {
  initialCountry: "auto",
  geoIpLookup: (callback) =>
    $.get("https://ipinfo.io", () => {}, "jsonp").always((resp) =>
      callback(resp && resp.country ? resp.country : "")
    ),
  utilsScript:
    "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/js/utils.min.js",
});

// Select2
$(document).ready(function () {
  $(".js-example-basic-multiple").select2();
});

// Swiper
var mySwiper = new Swiper(".swiper-container", {
  // Optional parameters
  direction: "horizontal",
  loop: true,

  speed: 1500,

  autoplay: {
    delay: 3000,
  },
  // If we need pagination
  pagination: {
    el: ".swiper-pagination",
  },

  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
});
